import {
  EN,
  ID
} from '../shared/constants/locale';

export const CHANGE_ALL_PUBLISH_PRICE = {
  [EN]: 'Modify All Rental Prices',
  [ID]: 'Ganti Semua Harga Rental'
};

export const CHANGE_ALLOTMENT_COUNT = {
  [EN]: 'Modify All Car Allotment',
  [ID]: 'Ganti Semua Alokasi Mobil'
};

export const CHANGE_STATUS = {
  [EN]: 'Modify All Status',
  [ID]: 'Ganti Semua Status',
};

export const CHANGE_ALL = {
  [EN]: 'Modify All',
  [ID]: 'Ganti Semua'
};

export const CHANGE_ALL_TOOLTIP_MESSAGE = {
  [EN]: 'Changes will be reflected on selected dates.',
  [ID]: 'Perubahan akan diterapkan pada tanggal yang dipilih.',
};

export const CHANGE_CONFIRMATION_MESSAGE = {
  [EN]: 'Are you sure?',
  [ID]: 'Apakah Anda yakin?',
};

export const MINIMUM_PRICE_WARNING_MESSAGE = {
  [EN]: (enteredPrice, minimumPrice) => `The amount that you’ve entered (${enteredPrice}) is below the minimum predefined price (${minimumPrice}).`,
  [ID]: (enteredPrice, minimumPrice) => `Nominal yang Anda masukan (${enteredPrice}) kurang dari harga minimum yang sudah ditentukan (${minimumPrice})`,
};

export const EDIT_ALLOTMENT_PREBUY = {
  [EN]: 'Edit Allotment / Prebuy',
  [ID]: 'Edit Allotment / Prebuy',
};

export const RENTAL_PRICE = {
  [EN]: 'Rental Price',
  [ID]: 'Harga Rental',
};

export const CAR_ALLOTMENT = {
  [EN]: 'Car Allotment',
  [ID]: 'Alokasi Mobil',
};

export const CAR_AVAILABILITY = {
  [EN]: 'Car Availablity',
  [ID]: 'Ketersediaan Mobil',
};

export const NO_ALLOTMENT = {
  [EN]: 'No Allotment Found',
  [ID]: 'Alokasi Mobil tidak ditemukan',
};

export const CAR_ALLOTMENT_DETAIL = {
  [EN]: 'Car Allotment Detail',
  [ID]: 'Detail Alokasi Mobil',
};

export const CAR_ALLOTMENT_LIST = {
  [EN]: 'Car Allotment List',
  [ID]: 'Daftar Alokasi Mobil',
};

export const TOTAL_BOOKING = {
  [EN]: 'Total Booking',
  [ID]: 'Jumlah Pemesanan'
};

export const PUBLISH_PRICE = {
  [EN]: 'Publish Price',
  [ID]: 'Harga Sewa'
};

export const NET_PRICE = {
  [EN]: 'Net Price',
  [ID]: 'Harga Pre-buy'
};

export const ALLOTMENT_COUNT = {
  [EN]: 'Allotment',
  [ID]: 'Jumlah Alokasi'
};

export const BOOKING_COUNT = {
  EN: 'Booking',
  ID: 'Jumlah Pemesanan'
};

export const ENABLE_SELECTED_STATUS = {
  [EN]: 'Enable selected dates',
  [ID]: 'Aktifkan tanggal yang dipilih',
};

export const DISABLE_SELECTED_STATUS = {
  [EN]: 'Disable selected dates',
  [ID]: 'Non-aktifkan tanggal yang dipilih',
};

export const CHANGE_CAR_ALLOTMENT = {
  [EN]: 'Change Car Allotment',
  [ID]: 'Ubah Alokasi Mobil',
};

export const CHANGE_ALL_CAR_ALLOTMENT = {
  [EN]: 'Change All Car Allotment',
  [ID]: 'Ubah Semua Alokasi Mobil',
};

export const TOTAL_CAR_AVAILABILITY = {
  [EN]: 'Car Availability',
  [ID]: 'Jumlah Ketersediaan Mobil',
};

export const TOTAL_CAR_ALLOTMENT = {
  [EN]: 'Total Car Allotment',
  [ID]: 'Jumlah Alokasi Mobil',
};

export const MANAGE_CAR_AVAILABILITY = {
  [EN]: 'Manage Car Availability',
  [ID]: 'Atur Ketersediaan Mobil',
};

export const MANAGE_CAR_AVAILABILITY_DESCRIPTION = {
  [EN]: 'Manage your car availability on a specified time and date using these buttons',
  [ID]: 'Atur jumlah ketersediaan mobil di waktu dan tanggal yang ditentukan menggunakan kedua tombol ini',
};

export const CHANGE_DRIVERLESS_ALLOTMENT_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, date) => `Are you sure you want to ${ value ? 'enable' : 'disable'} allotment on ${date} ?`,  
  [ID]: (value, date) => `Apakah anda ingin ${ value ? 'mengaktifkan' : 'menonaktifkan'} alokasi pada ${date} ?`
};