import React from 'react';
import { css, cx, keyframes } from 'react-emotion';

import {
  TYPE_NOTIFICATION_BUTTON_STATE_ACTIVE,
  TYPE_NOTIFICATION_BUTTON_STATE_IDLE,
} from 'shared/constants/type';

import { Badge, BADGE_COLOR, Button } from '@traveloka/soya-components';

// Styles
const roundRadius = css`
  border-radius: 50%;
`;
const defaultButtonStyle = css`
  &.notifBtn {
    background-color: #ffffff;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

    & .notifIcon {
      color: #fc9100;
    }

    &&:active {
      background-color: #ffffff;
      opacity: 0.75;

      & .notifIcon {
        color: #fc9100;
      }
    }
    &&:hover {
      background-color: #fc9100;

      & .notifIcon {
        color: #ffffff;
      }
    }
  }
`;
const totalUnreadPosition = css`
  position: relative;
  right: 10px;
  bottom: 10px;
`;

function generateButtonStyle(status) {
  switch (status) {
    case TYPE_NOTIFICATION_BUTTON_STATE_ACTIVE: {
      const bellRingingKeyframe = keyframes`
        0% { transform: rotate(0); }
        1% { transform: rotate(30deg); }
        3% { transform: rotate(-28deg); }
        5% { transform: rotate(34deg); }
        7% { transform: rotate(-32deg); }
        9% { transform: rotate(30deg); }
        11% { transform: rotate(-28deg); }
        13% { transform: rotate(26deg); }
        15% { transform: rotate(-24deg); }
        17% { transform: rotate(22deg); }
        19% { transform: rotate(-20deg); }
        21% { transform: rotate(18deg); }
        23% { transform: rotate(-16deg); }
        25% { transform: rotate(14deg); }
        27% { transform: rotate(-12deg); }
        29% { transform: rotate(10deg); }
        31% { transform: rotate(-8deg); }
        33% { transform: rotate(6deg); }
        35% { transform: rotate(-4deg); }
        37% { transform: rotate(2deg); }
        39% { transform: rotate(-1deg); }
        41% { transform: rotate(1deg); }

        43% { transform: rotate(0); }
        100% { transform: rotate(0); }
      `;

      return css`
        &.notifBtn {
          box-shadow: 0px 4px 10px #f4555a;

          & .notifIcon {
            animation: ${bellRingingKeyframe} 4s 1s ease-in-out infinite;
          }
        }
      `;
    }

    case TYPE_NOTIFICATION_BUTTON_STATE_IDLE:
    default:
      // Using default style
      return null;
  }
}

export default class NotificationButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      status = TYPE_NOTIFICATION_BUTTON_STATE_IDLE,
      totalUnread = 0,
      onClick,
    } = this.props;

    const iconProps = {
      className: 'notifIcon',
      icon: 'notifications',
      size: 24,
    };

    return (
      <React.Fragment>
        <Button
          className={cx(
            'notifBtn',
            roundRadius,
            defaultButtonStyle,
            generateButtonStyle(status)
          )}
          type={'button'}
          iconProps={iconProps}
          onClick={onClick}
        />

        {totalUnread > 0 && (
          <Badge className={totalUnreadPosition} color={BADGE_COLOR.RED}>
            {totalUnread}
          </Badge>
        )}
      </React.Fragment>
    );
  }
}
