import React from 'react';
import NoSSR from 'react-no-ssr';
import NextRouter from 'next/router';
import PropTypes from 'prop-types';
import { applyReducers } from 'soya-next/redux';

import { getWebModeFromUrl } from 'shared/utils/ClientStateUtil';
import { handleRequiredPermissionsByUrl } from './LayoutUtil';

import path from 'shared/constants/path';
import PagePropertyReducer from 'shared/reducers/PagePropertyReducer';

import AccessDeniedBox from '../AccessDeniedBox/AccessDeniedBox';
import LayoutContent from './LayoutContent';
import { OIDCAuthorize, OIDCAuthorizeView } from '@traveloka/soya-auth';

// TODO: add this import if there is abnormalities across browser
import '@traveloka/soya-components/styles';
import 'semantic-ui-css/semantic.min.css';
import PushNotification from '../PushNotification/PushNotification';
import PermissionWrapper from '../PermissionWrapper/PermissionWrapper';
import { NOTIFICATION_BUTTON_PERMISSIONS } from 'shared/constants/permissions';
import { PAGE_PROPERTY_ID } from 'shared/constants/PagePropertyConstant';
import NotificationContainer from 'shared/components/Notification/NotificationContainer';

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { permissions: pagePermissions, children, url, isWide } = this.props;

    const mode = getWebModeFromUrl(url);
    const permissions = handleRequiredPermissionsByUrl([], url);

    if (pagePermissions instanceof Array) {
      pagePermissions.forEach(value => {
        if (value) {
          permissions.push(value);
        }
      });
    } else {
      if (pagePermissions) {
        permissions.push(pagePermissions);
      }
    }

    return (
      <NoSSR>
        <OIDCAuthorize
          loadingComponent={() => <div>{'Loading...'}</div>}
          requiredPermissions={permissions}
          unauthorizedCallback={() => {
            alert('Unauthorized!');
            NextRouter.push(path.base);
          }}
        >
          <OIDCAuthorizeView permissions={permissions}>
            <OIDCAuthorizeView.Allow>
              <LayoutContent mode={mode} isWide={isWide}>
                {children}
              </LayoutContent>
              <PermissionWrapper permission={NOTIFICATION_BUTTON_PERMISSIONS}>
                <PushNotification />
                <NotificationContainer />
              </PermissionWrapper>
            </OIDCAuthorizeView.Allow>

            <OIDCAuthorizeView.Deny>
              <AccessDeniedBox />
            </OIDCAuthorizeView.Deny>
          </OIDCAuthorizeView>
        </OIDCAuthorize>
      </NoSSR>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  url: PropTypes.string,
  isWide: PropTypes.bool,
  permissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

Layout.defaultProps = {
  isWide: false,
};

export default applyReducers({
  [PAGE_PROPERTY_ID]: PagePropertyReducer,
})(Layout);
