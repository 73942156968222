import React from 'react';

import { Nav, NavItem, NavLink } from '@traveloka/soya-components';

class NavSubMenuItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showChildren: false
    }

    this.handleHideChildren = this.handleHideChildren.bind(this);
    this.handleShowChildren = this.handleShowChildren.bind(this);
  }

  handleShowChildren() {
    this.setState({
      showChildren: true,
    })
  }

  handleHideChildren() {
    this.setState({
      showChildren: false,
    })
  }

  checkActiveLink(itemPath, hasChildren) {
    const { pathname, search, hash } = window.location;
    const currentPath = `${pathname}${search}${hash}`;
    if (hasChildren) {
      return currentPath.includes(itemPath);
    }
    return currentPath === itemPath;
  }

  renderMenu(menu) {
    return menu.map((item, index) => {
      const navLinkProps = {
        href: item.path,
        children: item.title,
      };

      return (
        <NavItem key={index} isActive={this.checkActiveLink(item.activePath, !!item.children)}>
          <NavLink {...navLinkProps} />
        </NavItem>
      );
    })
  }


  render() {
    const { itemChildren, path, title, activePath } = this.props;
    const { showChildren } = this.state;

    const navLinkProps = {
      href: path,
      children: title,
    };

    return (
      <NavItem isActive={this.checkActiveLink(activePath, !!itemChildren)} onMouseEnter={this.handleShowChildren} onMouseLeave={this.handleHideChildren}>
        <NavLink {...navLinkProps} />
        {itemChildren && showChildren && <Nav isSubMenu style={{ marginLeft: '100%', marginTop: '-25%'}} >{this.renderMenu(itemChildren)}</Nav>}
      </NavItem>
    )
  }
}

export default NavSubMenuItem;