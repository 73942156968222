import React, { Component } from 'react';
import PropTypes from 'prop-types';
import config from 'config';
import classNames from 'classnames/dedupe';

import { Nav, NavLink, NavItem, Container } from '@traveloka/soya-components';
import withMarginPadding from '@traveloka/soya-components/lib/components/common/HOC/withMarginPadding';
import zettaStyles from '@traveloka/soya-components/lib/components/common/NavBar/styles/NavBar.styles.zetta';
import teraStyles from '@traveloka/soya-components/lib/components/common/NavBar/styles/NavBar.styles.tera';

import NavSubMenuItem from './NavSubMenuItem';

class NavBar extends Component {
  static displayName = 'NavBar';

  static propTypes = {
    className: PropTypes.string,
    menu: PropTypes.array.isRequired,
    /**
     * Theme for the breadcrumb.The value is either `zetta` or `tera`.
     */
    theme: PropTypes.string,
  };

  static defaultProps = {
    menu: [],
    theme: config.has('theme') ? config.get('theme') : 'zetta',
  };

  checkActiveLink(itemPath, hasChildren) {
    const { pathname, search, hash } = window.location;
    const currentPath = `${pathname}${search}${hash}`;
    if (hasChildren) {
      return currentPath.includes(itemPath);
    }
    return currentPath === itemPath;
  }

  renderSubMenu(menu) {
    return menu.map((item, index) => {
      return (
        <NavSubMenuItem
          key={index}
          path={item.path}
          title={item.title} 
          activePath={item.path}
          itemChildren={item.children}
        />
      );
    })
  }

  renderMainMenu(menu) {
    return menu.map((item, index) => {
      const navLinkProps = {
        href: item.path,
        children: item.title,
      };

      return (
        <NavItem key={index} isActive={this.checkActiveLink(item.activePath, !!item.children)}>
          <NavLink {...navLinkProps} />
          {item.children && <Nav isSubMenu >{this.renderSubMenu(item.children)}</Nav>}
        </NavItem>
      );
    });
  }

  render() {
    const { className, menu, theme, ...props } = this.props;

    // Theming
    let styles;
    switch (theme) {
      case 'tera':
        styles = teraStyles;
        break;
      default:
        styles = zettaStyles;
    }

    return (
      <div {...props} className={classNames('c-navbar', `c-navbar--theme-${theme}`, styles, className)}>
        <Container>
          <Nav isNavBar theme={theme}>
            {this.renderMainMenu(menu)}
          </Nav>
        </Container>
      </div>
    );
  }
}

export default withMarginPadding(NavBar);