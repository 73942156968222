import { post } from 'shared/utils/RequestHandler';
import { getResponseData } from 'shared/utils/ResponseHandler';
import {
  API_GET_USER,
  API_GET_USER_GROUPS,
  API_SEARCH_NOTIFICATIONS,
  API_UPDATE_NOTIFICATIONS,
} from 'shared/constants/apiUrl';
import { DefaultResponse } from '@aries/services';
import { BackendUser, UserGroup } from '@aries/user';

// @TODO: need to clarify type
export const searchUserNotifications = async (params: any) => {
  const {
    notificationId,
    subjectType,
    notificationType,
    subjectId,
    recipientId,
    isRead,
    createdTimestampStart,
    createdTimestampEnd,
    numberOfDisplayItems,
    isDeleted,
    page,
  } = params;

  const { response, error } = await post(API_SEARCH_NOTIFICATIONS, {
    notificationId,
    subjectType,
    notificationType,
    subjectId,
    recipient: recipientId,
    isRead,
    isDeleted,
    createdTimestampStart,
    createdTimestampEnd,
    numberOfDisplayItems,
    page,
  });

  let result = {
    data: [],
    pages: 1,
    totalPages: 1,
    totalItems: 0,
    totalUnreadItems: 0,
  };

  if (!error) {
    const {
      notifications,
      pages,
      totalPages,
      totalItems,
      totalUnreadItems,
    } = getResponseData(response);

    result = {
      data: notifications.map((notification: any) => {
        const additionalData =
          (notification && notification.additionalData) || {};

        return {
          id: notification.notificationId,
          subjectType: notification.subjectType,
          subjectId: notification.subjectId,
          notificationType: notification.notificationType,
          recipientId: notification.recipient,
          state: notification.state,
          isDeleted: notification.isDeleted,
          isRead: notification.isRead,
          content: notification.content,
          title: notification.title,
          createdTimestamp: notification.createdTimestamp,
          additionalData: {
            bookingCode: additionalData.bookingCode,
            bookingId: additionalData.bookingId,
            isResolved: additionalData.isResolved,
          },
        };
      }),
      pages,
      totalPages,
      totalItems,
      totalUnreadItems,
    };
  }

  return {
    error,
    response,
    result,
  };
};

// @TODO: need to clarify type
export const updateUserNotifications = async (params: any) => {
  const { notificationIds, isRead, isDeleted } = params;

  const { response, error } = await post(API_UPDATE_NOTIFICATIONS, {
    notificationIds,
    isRead,
    isDeleted,
  });

  return {
    error,
    response,
  };
};

interface GetUserGroupsSpec {}

interface GetUserGroupsResponse extends DefaultResponse {
  groups?: UserGroup[];
  mandatoryGroups?: UserGroup[];
}

export interface GetUserSpec {
  extranetUserId: number;
}

export interface GetUserResponse extends DefaultResponse {
  user: BackendUser;
}

export const getUserGroups = (payload: GetUserGroupsSpec = {}) =>
  post<GetUserGroupsResponse>(API_GET_USER_GROUPS, payload);

export const getUser = (payload: GetUserSpec) =>
  post<GetUserResponse>(API_GET_USER, payload);
