import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { set as setCookie, get as getCookie } from 'js-cookie';

import config from 'config';

import { upsertNotificationToken } from 'shared/services/NotificationService';

import { FIREBASE_TOKEN_KEY } from 'shared/constants/constants';

class PushNotification extends React.Component {
  async updateFirebaseToken(identifier) {
    const messaging = firebase.messaging();

    try {
      await messaging.requestPermission();
      const token = await messaging.getToken();

      const cookieFirebaseToken = getCookie(FIREBASE_TOKEN_KEY);

      if (cookieFirebaseToken && token === cookieFirebaseToken) {
        return;
      }

      // TODO: change identifier
      await upsertNotificationToken({
        token,
        identifier,
      });

      setCookie(FIREBASE_TOKEN_KEY, token, { expires: 1 });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config.get('firebaseConfig'));
    }

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();

      this.updateFirebaseToken('');

      messaging.onTokenRefresh(() => {
        this.updateFirebaseToken('');
      });
    }
  }

  render() {
    return null;
  }
}

export default PushNotification;
