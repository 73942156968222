import { ALL_VALUE } from 'shared/utils/Selection';
import { TYPE_BOOKING_SORT_COLUMN_PICKUP_TIME } from 'shared/constants/type';

export const PICKUP_MANAGEMENT_SEARCH_FILTER_ID =
  'pickupManagementSearchFilter';

export const PICKUP_MANAGEMENT_SEARCH_FILTER_FIELDS = {
  SUPPLIER_ID: 'supplierId',
  SERVICE_CITY_ID: 'serviceCityId',
  VEHICLE_ID: 'vehicleId',
  PARTNER_BOOKING_CODE: 'partnerBookingCode',
  BOOKING_STATUS: 'bookingStatus',
  ISSUANCE_STATUS: 'issuanceStatus',
  POST_ISSUANCE_STATUSES: 'postIssuanceStatuses',
  DRIVER_NAME: 'driverName',
  CAR_PLATE_NUMBER: 'carPlateNumber',
  IS_PICKUP_DETAIL_ASSIGNED: 'isPickupDetailAssigned',
  BOOKING_DATE_RANGE: 'bookingDateRange',
  PICKUP_DATE_RANGE: 'pickupDateRange',
  PICKUP_START_TIME: 'pickupStartTime',
  PICKUP_END_TIME: 'pickupEndTime',
  SORT_BY: 'sortBy',
  SORT_TYPE: 'sortType',
  IS_RESOLVED: 'isResolved',
  IS_RESCHEDULED: 'isRescheduled',
  BOOKING_CODE_OR_PARTNER_BOOKING_CODE: 'bookingCodeOrPartnerBookingCode',
};

export const PICKUP_MANAGEMENT_TAB_TYPE_BOOKING_URGENT = 'bookingUrgent';
export const PICKUP_MANAGEMENT_TAB_TYPE_LATE_PICKUP = 'latePickup';
export const PICKUP_MANAGEMENT_TAB_TYPE_RESCHEDULED_BOOKINGS =
  'rescheduledBookings';
export const PICKUP_MANAGEMENT_TAB_TYPE_ZERO_TWO_HOURS = 'zeroTwoHours';
export const PICKUP_MANAGEMENT_TAB_TYPE_TWO_SIX_HOURS = 'twoSixHours';
export const PICKUP_MANAGEMENT_TAB_TYPE_ZERO_ONE_HOURS = 'zeroOneHours';
export const PICKUP_MANAGEMENT_TAB_TYPE_ONE_THREE_HOURS = 'oneThreeHours';
export const PICKUP_MANAGEMENT_TAB_TYPE_THREE_SIX_HOURS = 'threeSixHours';
export const PICKUP_MANAGEMENT_TAB_TYPE_MORE_THAN_SIX_HOURS =
  'moreThanSixHours';
export const PICKUP_MANAGEMENT_TAB_TYPE_ALL = 'all';

export const PICKUP_MANAGEMENT_SEARCH_FILTER_FIELDS_DEFAULT_VALUE = {
  SUPPLIER_ID: ALL_VALUE,
  BOOKING_CODE_OR_PARTNER_BOOKING_CODE: null,
  BOOKING_STATUS: ALL_VALUE,
  SERVICE_CITY_ID: ALL_VALUE,
  VEHICLE_ID: ALL_VALUE,
  ISSUANCE_STATUS: ALL_VALUE,
  PICKUP_START_TIME: { hour: 0, minute: 0 },
  PICKUP_END_TIME: { hour: 23, minute: 59 },
  SORT_BY: TYPE_BOOKING_SORT_COLUMN_PICKUP_TIME,
  SORT_TYPE: 'ASC',
};
