import path from 'shared/constants/path';
import {
  BOOKING_MANIFEST_PAGE_PERMISSIONS,
  FAILED_PAYMENT_PAGE_PERMISSIONS,
  ALLOTMENT_AND_RATE_PAGE_PERMISSIONS,
  PARTNER_CHANGES_PAGE_PERMISSIONS,
  SUPPLIER_PAGE_PERMISSIONS,
  USER_PAGE_PERMISSIONS,
  SEASON_PAGE_PERMISSIONS,
  CAR_TYPE_PAGE_PERMISSIONS,
  CITY_PAGE_PERMISSIONS,
  PREBUY_PAGE_PERMISSIONS,
  BOOKING_MANIFEST_SUMMARY_PAGE_PERMISSIONS,
  INVENTORY_MONITORING_PAGE_PERMISSIONS,
  POINT_TO_POINT_PERMISSIONS,
  RENTAL_DAILY_PERMISSIONS,
  VEHICLE_LICENSE_PLATE_PAGE_PERMISSIONS,
  DRIVER_PAGE_PERMISSIONS,
  DRIVER_REWARD_PAGE_PERMISSIONS,
  PRODUCT_PAGE_PERMISSIONS,
  FARE_PAGE_PERMISSIONS,
  BOOKING_REPORT_PAGE_PERMISSIONS,
  DRIVER_FEEDBACK_PAGE_PERMISSIONS,
  TOLL_PAGE_PERMISSIONS,
  SUPPLIER_CITY_PAGE_PERMISSIONS,
  CARPOOL_PAGE_PERMISSIONS,
  SUPPLIER_ZONE_RESOLVER_PAGE_PERMISSIONS,
  ALLOTMENT_DRIVERLESS_PAGE_PERMISSIONS,
  ALLOTMENT_DRIVERLESS_RATE_PAGE_PERMISSIONS,
  ALLOTMENT_DRIVERLESS_USAGE_PAGE_PERMISSIONS,
  PPS_READ_BOOKING_PERMISSIONS,
  INSTANT_BOOKING_DASHBOARD_PAGE_PERMISSIONS,
  DRIVER_ACCOUNT_PERMISSIONS,
  ROUTE_MAINTENANCE_PERMISSIONS,
  DOCUMENT_PAGE_PERMISSIONS,
  OPERATIONAL_HOUR_PERMISSIONS,
  SUPPLIER_DETAIL_PAGE_PERMISSIONS,
  PARTNER_CHANGES_AT_PERMISSION,
} from 'shared/constants/permissions';
import {
  BOOKING_MANIFEST,
  PARTNER_CHANGES,
  FAILED_PAYMENTS,
  LOGOUT,
  SUPPLIER_DATA,
  CITY_DETAILS,
  SEASON,
  CAR_TYPE,
  PREBUY,
  BOOKING_MANIFEST_SUMMARY,
  CHANGE_PASSWORD,
  INVENTORY_MONITORING,
  DRIVER,
  CHANGE_MODE,
  PICKUP_MANAGEMENT,
  ALLOTMENT_AND_FARE,
  FARE,
  MASTER_DATA,
  BOOKING_MANAGEMENT,
  TOLL,
  WITH_DRIVER,
  INVENTORY,
  ALLOTMENT_AND_RATE,
  ALLOTMENT,
  RATE,
  ALLOTMENT_AVAILABILITY,
  REDEEM_TICKET,
  DOCUMENT,
  SUPPLIER_CITY_PRODUCT,
  SUPPLIER_CITY_DATA,
  REPORTING,
} from 'translations/common';
import {
  POINT_TO_POINT_CODE,
  RENTAL_DAILY_CODE,
} from 'shared/constants/constants';
import { DRIVER_LICENSE_PLATE } from 'translations/booking-manifest';
import {
  DRIVER_ACCOUNT,
  DRIVER_REWARD,
  DRIVER_FEEDBACK,
} from 'translations/driver';
import {
  CAR_ALLOTMENT,
  CAR_AVAILABILITY,
} from 'translations/allotment-and-rates';
import { SUPPLIER_OPERATION, CONTACT_INFORMATION } from 'translations/supplier';

export const createLogoUrl = (mode) => {
  switch (mode) {
    case POINT_TO_POINT_CODE:
      return path.airportTransport.base;
    case RENTAL_DAILY_CODE:
      return path.carRental.base;
    default:
      return path.base;
  }
};

export const createHeaderMenu = (permissions, mode, locale, isExternal) => {
  switch (mode) {
    case POINT_TO_POINT_CODE:
      return createPointToPointHeaderMenu(permissions, locale, isExternal);
    case RENTAL_DAILY_CODE:
      return createRentalDailyHeaderMenu(permissions, locale, isExternal);
    default:
      return [];
  }
};

export const createRentalDailyHeaderMenu = (
  permissions,
  locale,
  isExternal
) => {
  const headerMenu = [];

  headerMenu.push({
    title: 'Home',
    path: path.carRental.base,
    activePath: path.carRental.base,
  });

  if (
    !isExternal &&
    (permissions.includes(SUPPLIER_PAGE_PERMISSIONS) ||
      permissions.includes(SUPPLIER_CITY_PAGE_PERMISSIONS) ||
      permissions.includes(CAR_TYPE_PAGE_PERMISSIONS) ||
      permissions.includes(SEASON_PAGE_PERMISSIONS) ||
      permissions.includes(CITY_PAGE_PERMISSIONS) ||
      permissions.includes(OPERATIONAL_HOUR_PERMISSIONS) ||
      permissions.includes(DOCUMENT_PAGE_PERMISSIONS) ||
      permissions.includes(PRODUCT_PAGE_PERMISSIONS))
  ) {
    const masterDataMenu = {
      title: MASTER_DATA[locale],
      children: [],
    };

    if (permissions.includes(SUPPLIER_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SUPPLIER_DATA[locale],
        path: path.carRental.supplier,
        activePath: path.carRental.supplier,
      });
    }

    if (permissions.includes(SUPPLIER_CITY_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SUPPLIER_OPERATION[locale],
        path: path.carRental.supplierOperation,
        activePath: path.carRental.supplierOperation,
      });
    }

    if (permissions.includes(OPERATIONAL_HOUR_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: 'Operational Hour',
        path: path.carRental.operationalHour,
        activePath: path.carRental.operationalHour,
      });
    }

    if (permissions.includes(SUPPLIER_CITY_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SUPPLIER_CITY_DATA[locale],
        path: path.carRental.supplierCity,
        activePath: path.carRental.supplierCity,
      });
    }

    if (permissions.includes(PRODUCT_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SUPPLIER_CITY_PRODUCT[locale],
        path: path.carRental.supplierCityProduct,
        activePath: path.carRental.supplierCityProduct,
      });
    }

    if (permissions.includes(CAR_TYPE_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: CAR_TYPE[locale],
        path: path.carRental.carType,
        activePath: path.carRental.carType,
      });
    }

    if (permissions.includes(SEASON_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SEASON[locale],
        path: path.carRental.season,
        activePath: path.carRental.season,
      });
    }

    if (permissions.includes(CITY_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: CITY_DETAILS[locale],
        path: path.carRental.city,
        activePath: path.carRental.city,
      });
    }

    if (permissions.includes(DOCUMENT_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: DOCUMENT[locale],
        path: path.carRental.document,
        activePath: path.carRental.document,
      });
    }

    headerMenu.push(masterDataMenu);
  }

  if (
    permissions.includes(ALLOTMENT_AND_RATE_PAGE_PERMISSIONS) ||
    permissions.includes(PARTNER_CHANGES_PAGE_PERMISSIONS) ||
    permissions.includes(INVENTORY_MONITORING_PAGE_PERMISSIONS) ||
    permissions.includes(PREBUY_PAGE_PERMISSIONS)
  ) {
    const allotmentAndRateMenu = {
      title: INVENTORY[locale],
      children: [],
    };

    if (permissions.includes(ALLOTMENT_AND_RATE_PAGE_PERMISSIONS)) {
      const withDriverMenu = {
        title: WITH_DRIVER[locale],
        children: [],
      };

      if (permissions.includes(ALLOTMENT_AND_RATE_PAGE_PERMISSIONS)) {
        withDriverMenu.children.push({
          title: ALLOTMENT_AND_RATE[locale],
          path: path.carRental.withDriverAllotmentAndRates,
          activePath: path.carRental.withDriverAllotmentAndRates,
        });
      }

      allotmentAndRateMenu.children.push(withDriverMenu);
    }

    if (
      permissions.includes(ALLOTMENT_DRIVERLESS_PAGE_PERMISSIONS) ||
      permissions.includes(ALLOTMENT_DRIVERLESS_RATE_PAGE_PERMISSIONS) ||
      permissions.includes(ALLOTMENT_DRIVERLESS_USAGE_PAGE_PERMISSIONS)
    ) {
      const withoutDriverMenu = {
        title: 'Driverless', // TODO: add translations
        children: [],
      };

      if (permissions.includes(ALLOTMENT_DRIVERLESS_PAGE_PERMISSIONS)) {
        withoutDriverMenu.children.push({
          title: ALLOTMENT[locale],
          path: path.carRental.driverlessAllotment,
          activePath: path.carRental.driverlessAllotment,
        });
      }

      if (permissions.includes(ALLOTMENT_DRIVERLESS_RATE_PAGE_PERMISSIONS)) {
        withoutDriverMenu.children.push({
          title: RATE[locale],
          path: path.carRental.driverlessRate,
          activePath: path.carRental.driverlessRate,
        });
      }

      if (permissions.includes(ALLOTMENT_DRIVERLESS_USAGE_PAGE_PERMISSIONS)) {
        withoutDriverMenu.children.push({
          title: ALLOTMENT_AVAILABILITY[locale],
          path: path.carRental.driverlessAllotmentAvailability,
          activePath: path.carRental.driverlessAllotmentAvailability,
        });
      }

      allotmentAndRateMenu.children.push(withoutDriverMenu);
    }

    if (permissions.includes(PARTNER_CHANGES_PAGE_PERMISSIONS)) {
      allotmentAndRateMenu.children.push({
        title: PARTNER_CHANGES[locale],
        path: path.carRental.partnerChanges,
        activePath: path.carRental.partnerChanges,
      });
    }

    if (permissions.includes(INVENTORY_MONITORING_PAGE_PERMISSIONS)) {
      allotmentAndRateMenu.children.push({
        title: INVENTORY_MONITORING[locale],
        path: path.carRental.inventoryMonitoring,
        activePath: path.carRental.inventoryMonitoring,
      });
    }

    if (permissions.includes(PREBUY_PAGE_PERMISSIONS)) {
      allotmentAndRateMenu.children.push({
        title: PREBUY[locale],
        path: path.carRental.prebuy,
        activePath: path.carRental.prebuy,
      });
    }

    headerMenu.push(allotmentAndRateMenu);
  }

  // TODO: change permissions
  if (
    permissions.includes(DRIVER_REWARD_PAGE_PERMISSIONS) ||
    permissions.includes(DRIVER_FEEDBACK_PAGE_PERMISSIONS) ||
    permissions.includes(DRIVER_ACCOUNT_PERMISSIONS)
  ) {
    const driverMenu = {
      title: DRIVER[locale],
      children: [],
    };

    if (permissions.includes(DRIVER_ACCOUNT_PERMISSIONS)) {
      driverMenu.children.push({
        title: DRIVER_ACCOUNT[locale],
        path: path.carRental.driverAccount,
        activePath: path.carRental.driverAccount,
      });
    }

    if (permissions.includes(DRIVER_REWARD_PAGE_PERMISSIONS)) {
      driverMenu.children.push({
        title: DRIVER_REWARD[locale],
        path: path.carRental.driverReward,
        activePath: path.carRental.driverReward,
      });
    }

    if (permissions.includes(DRIVER_FEEDBACK_PAGE_PERMISSIONS)) {
      driverMenu.children.push({
        title: DRIVER_FEEDBACK[locale],
        path: path.carRental.driverFeedback,
        activePath: path.carRental.driverFeedback,
      });
    }

    headerMenu.push(driverMenu);
  }

  if (
    permissions.includes(BOOKING_MANIFEST_PAGE_PERMISSIONS) ||
    permissions.includes(BOOKING_MANIFEST_SUMMARY_PAGE_PERMISSIONS)
  ) {
    const bookingManifestMenu = {
      title: REPORTING[locale],
      children: [],
    };

    if (permissions.includes(BOOKING_MANIFEST_PAGE_PERMISSIONS)) {
      bookingManifestMenu.children.push({
        title: BOOKING_MANIFEST[locale],
        path: path.carRental.bookingManifest,
        activePath: path.carRental.bookingManifest,
      });
    }

    if (permissions.includes(BOOKING_MANIFEST_SUMMARY_PAGE_PERMISSIONS)) {
      bookingManifestMenu.children.push({
        title: BOOKING_MANIFEST_SUMMARY[locale],
        path: path.carRental.bookingManifestSummary,
        activePath: path.carRental.bookingManifestSummary,
      });
    }

    headerMenu.push(bookingManifestMenu);
  }

  if (permissions.includes(FAILED_PAYMENT_PAGE_PERMISSIONS)) {
    headerMenu.push({
      title: FAILED_PAYMENTS[locale],
      path: path.carRental.failedPayments,
      activePath: path.carRental.failedPayments,
    });
  }

  if (permissions.includes(SUPPLIER_DETAIL_PAGE_PERMISSIONS)) {
    headerMenu.push({
      title: CONTACT_INFORMATION[locale],
      path: path.carRental.contactInformation,
      activePath: path.carRental.contactInformation,
    });
  }

  if (permissions.includes(USER_PAGE_PERMISSIONS)) {
    headerMenu.push({
      // TODO: add translation
      title: 'User',
      path: path.carRental.user,
      activePath: path.carRental.user,
    });
  }

  return headerMenu;
};

export const createPointToPointHeaderMenu = (
  permissions,
  locale,
  isExternal
) => {
  const headerMenu = [];

  // TODO: change home path
  headerMenu.push({
    title: 'Home',
    path: path.airportTransport.base,
    activePath: path.airportTransport.base,
  });

  if (
    !isExternal &&
    (permissions.includes(SUPPLIER_PAGE_PERMISSIONS) ||
      permissions.includes(SUPPLIER_CITY_PAGE_PERMISSIONS) ||
      permissions.includes(SUPPLIER_ZONE_RESOLVER_PAGE_PERMISSIONS) ||
      permissions.includes(PRODUCT_PAGE_PERMISSIONS) ||
      permissions.includes(CAR_TYPE_PAGE_PERMISSIONS) ||
      permissions.includes(TOLL_PAGE_PERMISSIONS) ||
      permissions.includes(ROUTE_MAINTENANCE_PERMISSIONS))
  ) {
    const masterDataMenu = {
      title: MASTER_DATA[locale],
      children: [],
    };

    if (permissions.includes(SUPPLIER_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SUPPLIER_DATA[locale],
        path: path.airportTransport.supplier,
        activePath: path.airportTransport.supplier,
      });
    }

    if (permissions.includes(SUPPLIER_CITY_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: SUPPLIER_OPERATION[locale],
        path: path.airportTransport.supplierOperation,
        activePath: path.airportTransport.supplierOperation,
      });
    }

    if (permissions.includes(SUPPLIER_ZONE_RESOLVER_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: 'Supplier Zone Resolver', // TODO: add translations
        path: path.airportTransport.supplierZoneResolver(),
        activePath: path.airportTransport.supplierZoneResolver(),
      });
    }

    if (permissions.includes(PRODUCT_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: 'SKU', // TODO: add translation
        path: path.airportTransport.sku,
        activePath: path.airportTransport.sku,
      });
    }

    if (permissions.includes(CARPOOL_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: 'Car Pool', // TODO: add translations
        path: path.airportTransport.carPool,
        activePath: path.airportTransport.carPool,
      });
    }

    if (permissions.includes(CAR_TYPE_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: CAR_TYPE[locale],
        path: path.airportTransport.carType,
        activePath: path.airportTransport.carType,
      });
    }

    if (permissions.includes(TOLL_PAGE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: TOLL[locale],
        path: path.airportTransport.toll,
        activePath: path.airportTransport.toll,
      });
    }

    if (permissions.includes(ROUTE_MAINTENANCE_PERMISSIONS)) {
      masterDataMenu.children.push({
        title: 'Route Management', // TODO: add translation
        path: path.airportTransport.routeMaintenance,
        activePath: path.airportTransport.routeMaintenance,
      });
    }

    headerMenu.push(masterDataMenu);
  }

  if (
    permissions.includes(ALLOTMENT_AND_RATE_PAGE_PERMISSIONS) ||
    permissions.includes(FARE_PAGE_PERMISSIONS)
  ) {
    const allotmentAndRateMenu = {
      title: ALLOTMENT_AND_FARE[locale],
      children: [],
    };

    if (permissions.includes(ALLOTMENT_AND_RATE_PAGE_PERMISSIONS)) {
      allotmentAndRateMenu.children.push({
        title: CAR_ALLOTMENT[locale],
        path: path.airportTransport.allotment,
        activePath: path.airportTransport.allotment,
      });
    }

    // TODO: change permissions
    if (permissions.includes(ALLOTMENT_AND_RATE_PAGE_PERMISSIONS)) {
      allotmentAndRateMenu.children.push({
        title: CAR_AVAILABILITY[locale], // TODO: add translation
        path: path.airportTransport.allotmentDetail,
        activePath: path.airportTransport.allotmentDetail,
      });
    }

    if (permissions.includes(FARE_PAGE_PERMISSIONS)) {
      allotmentAndRateMenu.children.push({
        title: FARE[locale], // TODO: add translation
        path: path.airportTransport.fare,
        activePath: path.airportTransport.fare,
      });
    }

    if (permissions.includes(PARTNER_CHANGES_AT_PERMISSION)) {
      allotmentAndRateMenu.children.push({
        title: PARTNER_CHANGES[locale],
        path: path.airportTransport.partnerChanges,
        activePath: path.airportTransport.partnerChanges,
      });
    }

    headerMenu.push(allotmentAndRateMenu);
  }

  if (permissions.includes(PPS_READ_BOOKING_PERMISSIONS)) {
    headerMenu.push({
      title: REDEEM_TICKET[locale],
      path: path.airportTransport.redeem,
      activePath: path.airportTransport.redeem,
    });
  }

  if (permissions.includes(BOOKING_MANIFEST_PAGE_PERMISSIONS)) {
    headerMenu.push({
      title: BOOKING_MANAGEMENT[locale],
      path: path.airportTransport.booking,
      activePath: path.airportTransport.booking,
    });
  }

  // TODO: change permissions
  if (
    permissions.includes(BOOKING_MANIFEST_PAGE_PERMISSIONS) ||
    permissions.includes(DRIVER_PAGE_PERMISSIONS) ||
    permissions.includes(VEHICLE_LICENSE_PLATE_PAGE_PERMISSIONS)
  ) {
    const pickupManagementMenu = {
      title: PICKUP_MANAGEMENT[locale], // TODO: add translation
      children: [],
    };

    if (permissions.includes(BOOKING_MANIFEST_PAGE_PERMISSIONS)) {
      pickupManagementMenu.children.push({
        title: PICKUP_MANAGEMENT[locale], // TODO: add translation
        path: path.airportTransport.bookingPickupManagement,
        activePath: path.airportTransport.bookingPickupManagement,
      });
    }

    if (permissions.includes(DRIVER_PAGE_PERMISSIONS)) {
      pickupManagementMenu.children.push({
        title: DRIVER[locale],
        path: path.airportTransport.driver,
        activePath: path.airportTransport.driver,
      });
    }

    if (permissions.includes(VEHICLE_LICENSE_PLATE_PAGE_PERMISSIONS)) {
      pickupManagementMenu.children.push({
        title: DRIVER_LICENSE_PLATE[locale],
        path: path.airportTransport.carPlate,
        activePath: path.airportTransport.carPlate,
      });
    }

    headerMenu.push(pickupManagementMenu);
  }

  if (permissions.includes(INSTANT_BOOKING_DASHBOARD_PAGE_PERMISSIONS)) {
    headerMenu.push({
      title: 'Instant Booking', // TODO: add translation
      path: path.airportTransport.instantBookingDashboard,
      activePath: path.airportTransport.instantBookingDashboard,
    });
  }

  // TODO: change permissions
  if (permissions.includes(BOOKING_REPORT_PAGE_PERMISSIONS)) {
    headerMenu.push({
      title: 'Reporting', // TODO: add translation
      path: path.airportTransport.reporting,
      activePath: path.airportTransport.reporting,
    });
  }

  if (permissions.includes(USER_PAGE_PERMISSIONS)) {
    headerMenu.push({
      // TODO: add translation
      title: 'User',
      path: path.airportTransport.user,
      activePath: path.airportTransport.user,
    });
  }

  if (permissions.includes(DRIVER_ACCOUNT_PERMISSIONS)) {
    headerMenu.push({
      title: DRIVER_ACCOUNT[locale],
      path: path.airportTransport.driverAccount,
      activePath: path.airportTransport.driverAccount,
    });
  }

  return headerMenu;
};

export const createHeaderDropdownMenu = (webModes, locale) => {
  const headerMenu = [
    {
      title: CHANGE_PASSWORD[locale],
      href: path.changePassword,
    },
    {
      title: LOGOUT[locale],
      href: path.logout,
    },
  ];

  if (webModes.length > 1) {
    headerMenu.splice(1, 0, {
      title: CHANGE_MODE[locale],
      href: path.base,
    });
  }

  return headerMenu;
};

export const handleRequiredPermissionsByUrl = (requiredPermissions, url) => {
  if (url.indexOf(`/${POINT_TO_POINT_CODE}`) === 0) {
    return [...requiredPermissions, POINT_TO_POINT_PERMISSIONS];
  }

  if (url.indexOf(`/${RENTAL_DAILY_CODE}`) === 0) {
    return [...requiredPermissions, RENTAL_DAILY_PERMISSIONS];
  }

  return requiredPermissions;
};
