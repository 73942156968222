import path from 'shared/constants/path';
import { QUERY_NULL_SYMBOL } from 'shared/constants/constants';
import {
  TYPE_BOOKING_STATUS_ISSUED,
  TYPE_BOOKING_POST_ISSUANCE_STATUS_WAITING_FOR_ACKNOWLEDGE,
  TYPE_BOOKING_POST_ISSUANCE_STATUS_ACKNOWLEDGED,
  TYPE_BOOKING_POST_ISSUANCE_STATUS_DRIVER_ASSIGNED,
  TYPE_BOOKING_POST_ISSUANCE_ACTION_RESCHEDULE,
  TYPE_BOOKING_STATUS_CANCELLED,
  TYPE_BOOKING_POST_ISSUANCE_ACTION_REFUND,
  TYPE_BOOKING_POST_ISSUANCE_ACTION_REIMBURSE,
  TYPE_BOOKING_POST_ISSUANCE_ACTION_REBOOK,
  TYPE_BOOKING_POST_ISSUANCE_ACTION_BOOKING_FULFILLED,
} from 'shared/constants/type';
import { createUrlWithQuery } from '../QueryUtil';
import { EN } from 'shared/constants/locale';
import {
  NEED_CONFIRMATION,
  CONFIRMED,
  DRIVER_ASSIGNED,
} from 'translations/booking-manifest';

export const constructBookingManifestQuery = params => {
  const supplierId = params.supplierId || QUERY_NULL_SYMBOL;
  const serviceCityId = params.serviceCityId || QUERY_NULL_SYMBOL;
  const rentalStart = params.rentalStart || QUERY_NULL_SYMBOL;
  const rentalEnd = params.rentalEnd || QUERY_NULL_SYMBOL;
  const status = params.status || QUERY_NULL_SYMBOL;

  return `${supplierId}.${serviceCityId}.${rentalStart}.${rentalEnd}.${status}`;
};

export const createRentalDailyBookingCalendarRedirectUrlToBookingManifest = (
  supplierId,
  serviceCityId,
  rentalStart,
  rentalEnd
) => {
  const query = constructBookingManifestQuery({
    supplierId,
    serviceCityId,
    rentalStart,
    rentalEnd,
    status: TYPE_BOOKING_STATUS_ISSUED,
  });

  return createUrlWithQuery(path.carRental.bookingManifest, query);
};

export const parsePostIssuanceStatus = (postIssuanceStatus, locale = EN) => {
  switch (postIssuanceStatus) {
    case TYPE_BOOKING_POST_ISSUANCE_STATUS_WAITING_FOR_ACKNOWLEDGE:
      return NEED_CONFIRMATION[locale];
    case TYPE_BOOKING_POST_ISSUANCE_STATUS_ACKNOWLEDGED:
      return CONFIRMED[locale];
    case TYPE_BOOKING_POST_ISSUANCE_STATUS_DRIVER_ASSIGNED:
      return DRIVER_ASSIGNED[locale];
    default:
      return '';
  }
};

export const getPostIssuanceActionOptions = (status, isExternal) => {
  let postIssuanceActionOptions = [];

  if (isExternal) {
    if (status === TYPE_BOOKING_STATUS_ISSUED) {
      postIssuanceActionOptions = [
        {
          label: 'Reschedule',
          value: TYPE_BOOKING_POST_ISSUANCE_ACTION_RESCHEDULE,
        },
        {
          label: 'Booking Fulfilled',
          value: TYPE_BOOKING_POST_ISSUANCE_ACTION_BOOKING_FULFILLED,
        },
      ];
    }
  } else {
    if (status === TYPE_BOOKING_STATUS_CANCELLED) {
      postIssuanceActionOptions = [
        { label: 'Refund', value: TYPE_BOOKING_POST_ISSUANCE_ACTION_REFUND },
      ];
    } else if (status === TYPE_BOOKING_STATUS_ISSUED) {
      postIssuanceActionOptions = [
        {
          label: 'Reschedule',
          value: TYPE_BOOKING_POST_ISSUANCE_ACTION_RESCHEDULE,
        },
        {
          label: 'Booking Fulfilled',
          value: TYPE_BOOKING_POST_ISSUANCE_ACTION_BOOKING_FULFILLED,
        },
        {
          label: 'Reimburse',
          value: TYPE_BOOKING_POST_ISSUANCE_ACTION_REIMBURSE,
        },
        { label: 'Rebook', value: TYPE_BOOKING_POST_ISSUANCE_ACTION_REBOOK },
      ];
    }
  }

  return postIssuanceActionOptions;
};

export const constructPickupManagementQuery = params => {
  const tabType = params.tabType || QUERY_NULL_SYMBOL;
  const bookingCode = params.bookingCode || QUERY_NULL_SYMBOL;

  return `${tabType}.${bookingCode}`;
};

export const parsePickupManagementQuery = query => {
  if (!query) {
    return null;
  }

  const queryArray = query.split('.');

  if (queryArray.length !== 2) {
    return null;
  }

  return {
    tabType: queryArray[0] === QUERY_NULL_SYMBOL ? null : queryArray[0],
    bookingCode: queryArray[1] === QUERY_NULL_SYMBOL ? null : queryArray[1],
  };
};
