import { EN, ID } from '../shared/constants/locale';

export const SUPPLIER_OPERATION = {
  [EN]: 'Supplier Operation',
  [ID]: 'Operasi Supplier',
};

export const SUPPLIERS_NOT_FOUND = {
  [EN]: 'Suppliers not found. Please modify your search.',
  [ID]: 'Supplier tidak ditemukan. Mohon ganti pencarian Anda.',
};

export const CITIES_NOT_FOUND = {
  [EN]: 'City not found. Please modify your search.',
  [ID]: 'Kota tidak ditemukan. Mohon ganti pencarian Anda.',
};

export const SUPPLIER_SERVICE_CITIES_EMPTY = {
  [EN]: 'Supplier service cities is empty.',
  [ID]: 'Kota supplier masih kosong.',
};

export const SUPPLIER_CAR_TYPES_EMPTY = {
  [EN]: 'Supplier car types is empty.',
  [ID]: 'Tipe mobil supplier masih kosong.',
};

export const SUPPLIER_DRIVERS_EMPTY = {
  [EN]: 'Supplier drivers is empty.',
  [ID]: 'Sopir supplier masih kosong.',
};

export const SUPPLIER_OPERATION_ROUTES_EMPTY = {
  [EN]: 'Supplier operation route is empty.',
  [ID]: 'Rute service supplier masih kosong.',
};

export const MARKETING_MANAGER_CONTACT_EMPTY = {
  [EN]: 'Marketing manager contact is empty.',
  [ID]: 'Marketing manager masih kosong.',
};

export const COUNTRY_NAME_NOT_FOUND = {
  [EN]: 'Selected country not found',
  [ID]: 'Pilihan negara tidak ditemukan',
};

export const CARPOOL_NOT_FOUND = {
  [EN]: 'Car Pool not found',
  [ID]: 'Pool Mobil tidak ditemukan',
};

export const CONFIGURE_SUPPLIER_LEAD_TIME = {
  [EN]: 'Configure Supplier Lead Time',
  [ID]: 'Atur Lead Time Supplier',
};

export const CONFIGURE_SUPPLIER_CITY_LEAD_TIME = {
  [EN]: 'Configure Supplier City Lead Time',
  [ID]: 'Atur Lead Time Kota Supplier',
};

export const EDIT_LEAD_TIME_CONFIGURATION = {
  [EN]: 'Edit Lead Time Configuration',
  [ID]: 'Ubah Konfigurasi Lead Time',
};

export const EDIT_TIME_WINDOW_CONFIGURATION = {
  [EN]: 'Edit Time Window Configuration',
  [ID]: 'Ubah Konfigurasi Window Time',
};

export const CHANGE_SUPPLIER_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, supplierName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } supplier ${supplierName}?`,
  [ID]: (value, supplierName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } supplier ${supplierName}?`,
};

export const CHANGE_SUPPLIER_SERVICE_CITY_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, supplierName, cityName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } supplier ${supplierName} in ${cityName}?`,
  [ID]: (value, supplierName, cityName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } supplier ${supplierName} di kota ${cityName}?`,
};

export const CHANGE_SUPPLIER_SERVICE_CITY_INSTANT_BOOKING_CONFIGURATION_CONFIRMATION_MESSAGE = {
  [EN]: (value, supplierName, cityName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } instant booking for supplier ${supplierName} in ${cityName}?`,
  [ID]: (value, supplierName, cityName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } instant booking untuk supplier ${supplierName} di kota ${cityName}?`,
};

export const CHANGE_SUPPLIER_CAR_TYPE_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, vehicleName) =>
    `Are you sure you want to ${value ? 'enable' : 'disable'} ${vehicleName}?`,
  [ID]: (value, vehicleName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } ${vehicleName}}?`,
};

export const CHANGE_SUPPLIER_DRIVER_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, driverName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } driver ${driverName}?`,
  [ID]: (value, driverName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } sopir ${driverName}?`,
};

export const CHANGE_SUPPLIER_SERVED_ZONE_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, servedZoneName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } zone ${servedZoneName}?`,
  [ID]: (value, servedZoneName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } zona ${servedZoneName}?`,
};

export const CHANGE_SKU_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, supplierName, serviceCityName, vehicleName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } ${vehicleName} for supplier ${supplierName} in ${serviceCityName} city?`,
  [ID]: (value, supplierName, serviceCityName, vehicleName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } ${vehicleName} untuk supplier ${supplierName} di kota ${serviceCityName}?`,
};

export const CHANGE_CARPOOL_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, carPoolName) =>
    `Are you sure you want to ${value ? 'enable' : 'disable'} ${carPoolName}?`,
  [ID]: (value, carPoolName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } ${carPoolName}?`,
};

export const CHANGE_PARKING_FEE_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, zonalName) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } parking fee for zonal ${zonalName}?`,
  [ID]: (value, zonalName) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } biaya parkir untuk zonal ${zonalName}?`,
};

export const CHANGE_TOLL_FEE_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, zonalOrigin, zonalDestination) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } toll fee for zonal ${zonalOrigin} - ${zonalDestination} ?`,
  [ID]: (value, zonalOrigin, zonalDestination) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } biaya tol untuk zonal ${zonalOrigin} - ${zonalDestination} ?`,
};

export const CHANGE_POOL_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, poolAddress) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } pool at ${poolAddress} ?`,
  [ID]: (value, poolAddress) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } pool di ${poolAddress} ?`,
};

export const CHANGE_SUPPLIER_OPERATION_PICKUP_LANE_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: (value, pickupLane) =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } pool at ${pickupLane} ?`,
  [ID]: (value, pickupLane) =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } pool di ${pickupLane} ?`,
};

export const CHANGE_OPERATIONAL_HOUR_IS_ACTIVE_CONFIRMATION_MESSAGE = {
  [EN]: value =>
    `Are you sure you want to ${
      value ? 'enable' : 'disable'
    } this operational hour setting ?`,
  [ID]: value =>
    `Apakah anda ingin ${
      value ? 'mengaktifkan' : 'menonaktifkan'
    } pengaturan jam operasi ini ?`,
};

export const DELETE_OPERATIONAL_HOUR_CONFIRMATION_MESSAGE = {
  [EN]: 'Are you sure you want to delete this operational hour setting ?',
  [ID]: 'Apakah anda yakin ingin menghapus pengaturan jam operasi ini ?',
};

export const GENERAL_SETTING = {
  [EN]: 'General Setting',
  [ID]: 'Pengaturan Umum',
};

export const DRIVER_APPS_SETTING = {
  [EN]: 'Driver Apps Setting',
  [ID]: 'Pengaturan Aplikasi Driver',
};

export const DRIVER_APPS_STATUS = {
  [EN]: 'Driver Apps Status',
  [ID]: 'Status Driver Apps',
};

export const NOTIFICATION_SETTINGS = {
  [EN]: 'Notification Settings',
  [ID]: 'Pengaturan Notifikasi',
};

export const NOTIFICATION_TIME = {
  [EN]: 'Notification Time',
  [ID]: 'Waktu Notifikasi',
};

export const NOTIFICATION_TIME_VALUE_POSTFIX = {
  [EN]: 'before pickup time',
  [ID]: 'sebelum waktu penjemputan',
};

export const NOTIFICATION_CONFIRMATION_PERIOD = {
  [EN]: 'Notification Confirmation Period',
  [ID]: 'Periode Konfirmasi Notifikasi',
};

export const NOTIFICATION_CONFIRMATION_PERIOD_VALUE_POSTFIX = {
  [EN]: 'after notification is sent',
  [ID]: 'setelah notifikasi dikirim',
};

export const RENTAL_PRICE_AND_COMMISSION = {
  [EN]: 'Rental Price & Commission',
  [ID]: 'Harga Rental & Komisi',
};

export const PRICE_TO_SHOW = {
  [EN]: 'Price to Show',
  [ID]: 'Harga yang Ditampilkan',
};

export const CHAT_SETTINGS = {
  [EN]: 'Chat Settings',
  [ID]: 'Pengaturan Chat',
};

export const EDIT_DRIVER_APPS_SETTING = {
  [EN]: 'Edit Driver Apps Setting',
  [ID]: 'Ganti Pengaturan Driver Apps',
};

export const PIC_PHONE_NUMBER = {
  [EN]: 'PIC Phone Number',
  [ID]: 'Nomor Telepon PIC',
};

export const EDIT_PIC_PHONE_NUMBER = {
  [EN]: 'Edit PIC Phone Number',
  [ID]: 'Ganti Nomor Telepon PIC',
};

export const SHOW_COMMISSION_TO_DRIVER = {
  [EN]: 'Show Commission To Driver',
  [ID]: 'Tampilkan Komisi Sopir',
};

export const RENTAL_PRICE = {
  [EN]: 'Rental Price',
  [ID]: 'Harga Rental',
};

export const DRIVER_COMMISSION = {
  [EN]: 'Driver Commission',
  [ID]: 'Komisi Sopir',
};

export const COMMISSION_SETTING = {
  [EN]: 'Commision Settings',
  [ID]: 'Pengaturan Komisi',
};

export const NONE_PRICE = {
  [EN]: 'None',
  [ID]: 'Tidak Ada',
};

export const ENABLE_CHAT = {
  [EN]: 'Enable Chat',
  [ID]: 'Aktifkan Chat',
};

export const DRIVER_COMMISSION_CANNOT_EXCEED = {
  [EN]: 'Driver commission cannot exceed 100%',
  [ID]: 'Komisi driver tidak bisa melebihi 100%',
};

export const CONTACT_INFORMATION = {
  [EN]: 'Contact Information',
  [ID]: 'Informasi Kontak',
};

export const UPDATE_CITY_CONTACT_INFORMATION = {
  [EN]: 'Update City Contact Information',
  [ID]: 'Perbarui Informasi Kontak Kota',
};

export const ADDITIONAL_CONTACT = {
  [EN]: 'Additional Contacts',
  [ID]: 'Kontak Tambahan',
};

export const PRIMARY_OPERATOR_CONTACT = {
  [EN]: 'Primary Operator Contact',
  [ID]: 'Kontak Operator Utama',
};

export const ADD_PRIMARY_OPERATOR_CONTACT = {
  [EN]: 'Add Primary Operator Contact',
  [ID]: 'Tambah Kontak Operator Utama',
};

export const FINANCE_CONTACT = {
  [EN]: 'Finance Contact',
  [ID]: 'Kontak Finance',
};

export const ADD_FINANCE_CONTACT = {
  [EN]: 'Add Finance Contact',
  [ID]: 'Tambah Finance',
};

export const MARKETING_CONTACT = {
  [EN]: 'Marketing Contact',
  [ID]: 'Kontak Marketing',
};

export const ADD_MARKETING_CONTACT = {
  [EN]: 'Add Marketing Contact',
  [ID]: 'Tambah Marketing',
};
