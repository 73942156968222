import React from 'react';
import { css } from 'react-emotion';

import {
  Block,
  Column,
  Container,
  FlexBox,
  DropDown,
  DropDownItem,
  DropDownMenu,
  DropDownToggle,
  Row,
} from '@traveloka/soya-components';

import NavBar from 'shared/components/NavBar/NavBar';
import { EN, ID } from 'shared/constants/locale';

const dropDownToggleCss = css`
  color: #ffffff;
  opacity: 0.75;
  padding-right: 12px;

  :hover {
    opacity: 1;
  }
`;

const TopBar = props => {
  const { logoProps, dropDownProps, menu, localeSelectionProps } = props;

  const { imgSrc, href } = logoProps;
  const { locale, onChange: handleChange } = localeSelectionProps;

  const theme = 'zetta';

  const language = [
    { value: EN, label: `English (${EN})` },
    { value: ID, label: `Indonesian (${ID})` },
  ];

  return (
    <div style={{ backgroundColor: '#1c2129' }}>
      <Container style={{ padding: '28px 15px' }}>
        <Row valign={'middle'}>
          <Column size={6}>
            {imgSrc ? (
              <a href={href || '#'} style={{ display: 'block' }}>
                <img src={imgSrc} alt="logo" />
              </a>
            ) : (
              ''
            )}
          </Column>
          <Column size={6}>
            <FlexBox halign={'right'} valign={'middle'}>
              <Block marginRight={'sm'}>
                <DropDown theme={theme}>
                  <DropDownToggle tag={'div'} className={dropDownToggleCss}>
                    {locale}
                  </DropDownToggle>
                  <DropDownMenu isRight>
                    {language.map((item, index) => (
                      <DropDownItem
                        key={index}
                        isActive={item.value === locale}
                        value={item.value}
                        onClick={() => handleChange(item.value)}
                      >
                        {item.label}
                      </DropDownItem>
                    ))}
                  </DropDownMenu>
                </DropDown>
              </Block>
              <DropDown theme={theme}>
                {dropDownProps.title && (
                  <DropDownToggle tag={'div'} className={dropDownToggleCss}>
                    {dropDownProps.title}
                  </DropDownToggle>
                )}
                {dropDownProps.items && dropDownProps.items.length > 0 && (
                  <DropDownMenu isRight>
                    {dropDownProps.items.map((item, index) => (
                      <DropDownItem
                        key={index}
                        href={item.href}
                        onClick={() => item.onClick}
                      >
                        {item.title}
                      </DropDownItem>
                    ))}
                  </DropDownMenu>
                )}
              </DropDown>
            </FlexBox>
          </Column>
        </Row>
      </Container>
      {menu && menu.length > 0 ? <NavBar theme={theme} menu={menu} /> : ''}
    </div>
  );
};

export default TopBar;
