import { API_UPSERT_NOTIFICATION_TOKEN } from 'shared/constants/apiUrl';
import { post } from 'shared/utils/RequestHandler';

export const upsertNotificationToken = async params => {
  const {
    token,
    // identifier
  } = params;

  const { error } = await post(API_UPSERT_NOTIFICATION_TOKEN, {
    notificationToken: token,
    // identifier,
  });

  if (error) {
    return { isSuccess: false, error };
  }

  return { isSuccess: true };
};
