import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { OIDCUserSelector } from '@traveloka/soya-auth';
import {
  Block,
  Container,
  ModalContainer,
  NotificationContainer,
  zettaStyles,
} from '@traveloka/soya-components';

// TODO: add this import if there is abnormalities across browser
// import '@traveloka/soya-components/styles';
import 'semantic-ui-css/semantic.min.css';

import {
  createHeaderDropdownMenu,
  createHeaderMenu,
  createLogoUrl,
} from './LayoutUtil';

import TopBar from '../TopBar/TopBar';

import Helper from 'shared/utils/Helper';
import getPagePropertyState from 'shared/selectors/PagePropertySelector';

import { getWebModes } from 'shared/utils/ClientStateUtil';
import {
  initData,
  requestUserInfo,
  updateUserPreferredLanguage,
} from 'shared/actions/PagePropertyAction';

import logo from 'static/aries_logo.png';

// This component is created to prevent calling check user when logout
class LayoutContent extends React.Component {
  constructor(props) {
    super(props);

    this.handleUpdateLanguage = this.handleUpdateLanguage.bind(this);
  }

  componentDidMount() {
    const { initData } = this.props;

    initData();

    document.body.style.backgroundColor = zettaStyles.bodyBg;
  }

  handleUpdateLanguage(language) {
    const { locale: currentLanguage, onUpdateLanguage } = this.props;

    if (
      typeof onUpdateLanguage === 'function' &&
      currentLanguage !== language
    ) {
      onUpdateLanguage(language);
    }
  }

  render() {
    const {
      children,
      locale,
      isFetching,
      userInfo,
      isExternal,
      userPermissions: propUserPermissions,
      mode,
      isWide,
    } = this.props;

    const userPermissions = propUserPermissions || [];
    const webModes = getWebModes(userPermissions);

    const topBarProps = {
      logoProps: {
        imgSrc: logo,
        href: createLogoUrl(mode),
      },
      dropDownProps: {
        title: userInfo,
        items: createHeaderDropdownMenu(webModes, locale),
      },
      localeSelectionProps: {
        onChange: this.handleUpdateLanguage,
        locale,
        isFetching,
      },
      menu: createHeaderMenu(userPermissions, mode, locale, isExternal),
    };

    return (
      <React.Fragment>
        <TopBar {...topBarProps} />
        <Container style={isWide ? { width: 'auto' } : {}} marginTop={'md'}>
          {!isFetching ? children : ''}
        </Container>
        {/* Footer */}
        <Block paddingTop={32} />
        <NotificationContainer />
        <ModalContainer />
      </React.Fragment>
    );
  }
}

LayoutContent.propTypes = {
  children: PropTypes.node,
  locale: PropTypes.string,
  userInfo: PropTypes.string,
  isFetching: PropTypes.bool,
  isExternal: PropTypes.bool,
  isWide: PropTypes.bool,
  initData: PropTypes.func,
  userPermissions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};

LayoutContent.defaultProps = {
  isExternal: true,
  isWide: false,
};

const mapStateToProps = state => {
  const oidcSelector = OIDCUserSelector(state);
  const pageProperty = getPagePropertyState(state);
  const token = oidcSelector.getToken();

  return {
    accessToken: oidcSelector.getAccessToken(),
    userInfo: token.userEmail,
    userPermissions: Helper.getPermissionsFromState(state),
    locale: pageProperty.locale,
    isExternal: pageProperty.isExternal,
    isFetching: pageProperty.isFetching,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    initData: () => {
      dispatch(initData());
      dispatch(requestUserInfo());
    },
    onUpdateLanguage: language =>
      dispatch(updateUserPreferredLanguage(language)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LayoutContent
);
