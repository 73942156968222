import { NotificationManager } from '@traveloka/soya-components';
import ResponseHandler, { isResponseDataNull } from './ResponseHandler';
import {
  TYPE_NOTIFICATION_TYPE_CONFIRM_BOOKING,
  TYPE_NOTIFICATION_TYPE_ASSIGN_DRIVER,
  TYPE_NOTIFICATION_TYPE_REQUEST_REFUND,
  TYPE_NOTIFICATION_TYPE_REQUEST_RESCHEDULE,
  TYPE_NOTIFICATION_TYPE_POTENTIAL_LATE,
} from 'shared/constants/type/index';
import {
  NOTIFICATION_CONFIRM_BOOKING,
  NOTIFICATION_ASSIGN_DRIVER,
  NOTIFICATION_REQUEST_REFUND,
  NOTIFICATION_REQUEST_RESCHEDULE,
  NOTIFICATION_POTENTIAL_LATE,
  NOTIFICATION_BOOKING_CONFIRMED,
  NOTIFICATION_DRIVER_ASSIGNED,
  NOTIFICATION_REFUNDED,
  NOTIFICATION_RESCHEDULED,
  NOTIFICATION_POTENTIAL_LATE_SOLVED,
} from 'translations/notification';

const notifyResponseSuccess = (message = 'Success') =>
  NotificationManager.showSuccess(message);

// If response status code <> 200
const notifyRequestFailed = error => {
  if (error.response) {
    const response = error.response;
    const responseData = response.data;

    if (responseData.data) {
      const data = responseData.data;
      NotificationManager.showError(data.errorCode + ': ' + data.message);
    } else {
      // responseData.status is error code
      // responseData.statusText is error message
      let statusText = 'Something wrong';
      switch (response.status) {
        case 500:
          statusText = response.statusText;
          break;
        case 403:
          statusText =
            "Sorry, you don't have permission. Please contact System Administrators";
          break;
        default:
      }

      NotificationManager.showError(`HTTP ${response.status}: ${statusText}`);
    }
  } else {
    NotificationManager.showError(error.message);
  }
};

const notifyResponseStatus = (response, successMessage) => {
  const data = ResponseHandler.getResponseData(response);
  if (ResponseHandler.isResponseStatusFailed(response)) {
    notifyCustomError(data.errorCode, data.message);
  } else {
    const message = successMessage ? successMessage : data.message;

    notifyResponseSuccess(message ? message : 'Success');
  }
};

// If response status code == 200 but with status FAILED
const notifyResponseStatusFailed = response => {
  if (ResponseHandler.isResponseStatusFailed(response)) {
    const data = ResponseHandler.getResponseData(response);

    notifyCustomError(data.errorCode, data.message);
  }
};

// if response status code == 200 but there is no data
const notifyNoDataExisted = response => {
  if (isResponseDataNull(response)) {
    notifyCustomError('', 'Data Not Existing!');
  }
};

const notifyCustomError = (errorCode, message) => {
  NotificationManager.showError((errorCode ? errorCode + ': ' : '') + message);
};

export default {
  notifyRequestFailed,
  notifyResponseStatus,
  notifyResponseSuccess,
  notifyResponseStatusFailed,
  notifyCustomError,
  notifyNoDataExisted,
};

export const getNotificationAction = (notification, isResolved, locale) => {
  let message = '';

  if (notification === TYPE_NOTIFICATION_TYPE_CONFIRM_BOOKING) {
    message = isResolved
      ? NOTIFICATION_BOOKING_CONFIRMED[locale]
      : NOTIFICATION_CONFIRM_BOOKING[locale];
  } else if (notification === TYPE_NOTIFICATION_TYPE_ASSIGN_DRIVER) {
    message = isResolved
      ? NOTIFICATION_DRIVER_ASSIGNED[locale]
      : NOTIFICATION_ASSIGN_DRIVER[locale];
  } else if (notification === TYPE_NOTIFICATION_TYPE_REQUEST_REFUND) {
    message = isResolved
      ? NOTIFICATION_REFUNDED[locale]
      : NOTIFICATION_REQUEST_REFUND[locale];
  } else if (notification === TYPE_NOTIFICATION_TYPE_REQUEST_RESCHEDULE) {
    message = isResolved
      ? NOTIFICATION_RESCHEDULED[locale]
      : NOTIFICATION_REQUEST_RESCHEDULE[locale];
  } else if (notification === TYPE_NOTIFICATION_TYPE_POTENTIAL_LATE) {
    message = isResolved
      ? NOTIFICATION_POTENTIAL_LATE_SOLVED[locale]
      : NOTIFICATION_POTENTIAL_LATE[locale];
  }

  return message;
};
