import { EN, ID } from '../shared/constants/locale';

export const DO_SOMETHING = {
  [EN]: 'Take Action',
  [ID]: 'Lakukan Tindakan',
};
export const ALREADY_ASSIGN_THE_DRIVER = {
  [EN]: 'Already Assign the Driver',
  [ID]: 'Sudah Tugaskan Supir',
};
export const CLEAR_ALL_NOTIFICATION = {
  [EN]: 'Clear All Notification',
  [ID]: 'Hapus Semua Notifikasi',
};

export const LOAD_PREVIOUS = {
  [EN]: 'Load Previous',
  [ID]: 'Muat Sebelumnya',
};

export const NOTIFICATION_CONFIRM_BOOKING = {
  [EN]: 'Confirm Booking',
  [ID]: 'Konfirmasi Pesanan',
};

export const NOTIFICATION_ASSIGN_DRIVER = {
  [EN]: 'Assign Driver',
  [ID]: 'Tugaskan Sopir',
};

export const NOTIFICATION_REQUEST_REFUND = {
  [EN]: 'Process Refund',
  [ID]: 'Proses Refund',
};

export const NOTIFICATION_REQUEST_RESCHEDULE = {
  [EN]: 'Process Reschedule',
  [ID]: 'Proses Reschedule',
};

export const NOTIFICATION_POTENTIAL_LATE = {
  [EN]: 'Take Action',
  [ID]: 'Lakukan Tindakan',
};

export const NOTIFICATION_BOOKING_CONFIRMED = {
  [EN]: 'Booking Confirmed',
  [ID]: 'Sudah Konfirmasi',
};

export const NOTIFICATION_DRIVER_ASSIGNED = {
  [EN]: 'Driver Assigned',
  [ID]: 'Sudah Tugaskan Sopir',
};

export const NOTIFICATION_REFUNDED = {
  [EN]: 'Refunded',
  [ID]: 'Sudah Refund',
};

export const NOTIFICATION_RESCHEDULED = {
  [EN]: 'Rescheduled',
  [ID]: 'Sudah Reschedule',
};

export const NOTIFICATION_POTENTIAL_LATE_SOLVED = {
  [EN]: 'Solved',
  [ID]: 'Sudah Selesai',
};
