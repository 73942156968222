import React from 'react';
import { connect } from 'react-redux';
import Helper from '../../utils/Helper';

const PermissionWrapper = ({ tag, hasPermission, children }) => {
  if (!hasPermission) {
    return null;
  };
  
  if ( tag === 'p' ) {
    return (
      <p>
        { children }
      </p>  
    )
  }

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const permissions = Helper.getPermissionsFromState(state);
  const hasPermission = Helper.hasPermission(permissions, ownProps.permission);
  
  return {
    tag: ownProps.tag ? ownProps.tag : null,
    hasPermission
  }
};

export default connect(mapStateToProps)(PermissionWrapper);

