import React from 'react';
import moment from 'moment';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import NextRouter from 'next/router';

// import firebase from 'shared/libs/firebase';
import { css } from 'react-emotion';
import { connect } from 'react-redux';

import NotificationButton from 'shared/components/Notification/NotificationButton';
import NotificationCardContainer from 'shared/components/Notification/NotificationCardContainer';

import path from 'shared/constants/path';
import getPagePropertyState from 'shared/selectors/PagePropertySelector';

import {
  searchUserNotifications,
  updateUserNotifications,
} from 'shared/services/UserService';
import {
  setClientSessionState,
  getClientSessionState,
} from 'shared/utils/ClientStateUtil';
import { createUrlWithQuery } from 'shared/utils/QueryUtil';
import { constructPickupManagementQuery } from 'shared/utils/booking-manifest/Util';

import {
  TYPE_NOTIFICATION_BUTTON_STATE_ACTIVE,
  TYPE_NOTIFICATION_BUTTON_STATE_IDLE,
} from 'shared/constants/type';
import {
  TYPE_NOTIFICATION_STATE_NEW,
  TYPE_NOTIFICATION_TYPE_ASSIGN_DRIVER,
  TYPE_NOTIFICATION_TYPE_CONFIRM_BOOKING,
  TYPE_NOTIFICATION_TYPE_POTENTIAL_LATE,
  TYPE_NOTIFICATION_TYPE_REQUEST_REFUND,
  TYPE_NOTIFICATION_TYPE_REQUEST_RESCHEDULE,
  TYPE_NOTIFICATION_STATE_URGENT,
} from 'shared/constants/type/index';
import { PICKUP_MANAGEMENT_TAB_TYPE_ALL } from 'content/airport-transport/pickup-management/constants/PickupManagementSearchFilterConstant';

// Styles
const notificationContainerPosition = css`
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const DEFAULT_NOTIFICATION_COUNT = 100;
const NOTIFICATION_SESSION_KEY = 'notification';

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: {
        data: [],
        page: 0,
        isFetching: false,
      },
      isOpen: false,
      totalNewNotifications: 0,
      supportDesktopNotification: true, // to flag whether desktop notification is supported in the browser
    };

    this.handleToggleNotificationIsOpen = this.handleToggleNotificationIsOpen.bind(
      this
    );
    this.handleClearAllNotifications = this.handleClearAllNotifications.bind(
      this
    );
    this.handleNotificationCardCloseClick = this.handleNotificationCardCloseClick.bind(
      this
    );
    this.requestNotifications = this.requestNotifications.bind(this);
    this.requestNewNotifications = this.requestNewNotifications.bind(this);
    this.saveToSessionStorage = this.saveToSessionStorage.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.sortNotification = this.sortNotification.bind(this);
    this.handleNotificationCardClick = this.handleNotificationCardClick.bind(
      this
    );
    this.handleLoadPreviousNotifications = this.handleLoadPreviousNotifications.bind(
      this
    );
  }

  componentDidMount() {
    const notificationSession = getClientSessionState(NOTIFICATION_SESSION_KEY);

    if (notificationSession) {
      const { totalNewNotifications } = notificationSession;

      this.setState({
        supportDesktopNotification: window && 'Notification' in window,
        totalNewNotifications,
      });
    } else {
      this.requestNewNotificationsCount();
    }

    // handle when user change to other tab or app
    document.addEventListener('visibilitychange', this.handleVisibilityChange);

    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage(payload => {
        const { isOpen, supportDesktopNotification } = this.state;

        if (
          supportDesktopNotification &&
          Notification.permission === 'granted'
        ) {
          // not stored in varibale because it cause error in eslint, and the variable is not used in anywhere
          new Notification(payload.notification.title, {
            body: payload.notification.body,
          });
        }

        if (isOpen) {
          this.requestNewNotifications();
        } else {
          this.requestNewNotificationsCount();
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    );
  }

  handleVisibilityChange() {
    if (document.visibilityState === 'visible') {
      const { isOpen } = this.state;

      if (isOpen) {
        this.requestNewNotifications();
      } else {
        this.requestNewNotificationsCount();
      }
    }
  }

  async requestNewNotificationsCount() {
    const { result } = await searchUserNotifications({
      createdTimestampEnd: moment().unix(),
      isRead: null,
      isDeleted: false,
      page: 1,
      numberOfDisplayItems: 1, // set to 1 so it don't failed on backend
    });
    const { totalUnreadItems } = result;

    this.setState(
      {
        totalNewNotifications: totalUnreadItems,
      },
      this.saveToSessionStorage
    );
  }

  // use when user click load more, will always call when notification is open
  // will add notification to existing notifications array
  async requestNotifications() {
    const { notifications } = this.state;
    const { page } = notifications;

    const { result, error } = await searchUserNotifications({
      createdTimestampEnd: moment().unix(),
      isRead: null,
      isDeleted: false,
      page: page + 1,
      numberOfDisplayItems: DEFAULT_NOTIFICATION_COUNT,
    });

    if (error) {
      // TODO: need to find way to handle
      return;
    }

    const newNotifications = result.data.sort(this.sortNotification);

    // no need to await since we dont use the result
    const totalReadNewNotifications = this.markNotificationsAsRead(
      newNotifications
    );

    this.setState(
      prevState => ({
        notifications: {
          data: [...newNotifications, ...prevState.notifications.data], //
          page: newNotifications.length > 0 ? result.page : page, // if load previous is zero then stay on the same page
        },
        totalNewNotifications:
          prevState.totalNewNotifications - totalReadNewNotifications,
      }),
      this.saveToSessionStorage
    );
  }

  // will always call when notification is open
  // always replace notifications array instead of adding to existing
  async requestNewNotifications() {
    const { result, error } = await searchUserNotifications({
      createdTimestampEnd: moment().unix(),
      isRead: null,
      isDeleted: false,
      page: 1,
      numberOfDisplayItems: DEFAULT_NOTIFICATION_COUNT,
    });

    if (error) {
      // TODO: need to find way to handle
      return;
    }

    const newNotifications = result.data.sort(this.sortNotification);

    // no need to await since we dont use the result
    const totalReadNewNotifications = this.markNotificationsAsRead(
      newNotifications
    );

    this.setState(
      prevState => ({
        notifications: {
          data: newNotifications,
          page: 1,
        },
        totalNewNotifications:
          prevState.totalNewNotifications - totalReadNewNotifications,
      }),
      this.saveToSessionStorage
    );
  }

  markNotificationsAsRead(notifications) {
    const unreadNotifications = notifications.filter(
      notification => !notification.isRead
    );
    const totalUnreadNotifications = unreadNotifications.reduce(
      (count, notification) => {
        return (
          Number(count) +
          (notification.state === TYPE_NOTIFICATION_STATE_NEW ||
          notification.state === TYPE_NOTIFICATION_STATE_URGENT
            ? 1
            : 0)
        );
      },
      0
    );

    if (totalUnreadNotifications) {
      updateUserNotifications({
        notificationIds: unreadNotifications.map(
          notification => notification.id
        ),
        isRead: true,
        isDeleted: false,
      });
    }

    return totalUnreadNotifications;
  }

  // sort notification by createdTimestamp in asc,
  // consider to use .reverse() if data from backend already sorted by createdTimestamp in desc
  sortNotification(firstNotification, secondNotification) {
    if (
      firstNotification.createdTimestamp < secondNotification.createdTimestamp
    ) {
      return -1;
    } else if (
      firstNotification.createdTimestamp > secondNotification.createdTimestamp
    ) {
      return 1;
    }

    return 0;
  }

  saveToSessionStorage() {
    const { totalNewNotifications } = this.state;

    setClientSessionState(NOTIFICATION_SESSION_KEY, {
      totalNewNotifications,
    });
  }

  handleToggleNotificationIsOpen() {
    const { isOpen } = this.state;

    const newValue = !isOpen;

    if (newValue) {
      this.requestNewNotifications();
    }

    this.setState({
      isOpen: newValue,
    });
  }

  async handleNotificationCardCloseClick(selectedNotificationId) {
    const { notifications, totalNewNotifications } = this.state;

    this.setState({
      notifications: {
        ...notifications,
      },
    });

    const selectedNotification = notifications.data.find(
      notification => notification.id === selectedNotificationId
    );

    if (!selectedNotification) {
      return;
    }

    const newNotifications = notifications.data.filter(
      notification => notification.id !== selectedNotification.id
    );

    const { error } = await updateUserNotifications({
      notificationIds: [selectedNotificationId],
      isRead: true,
      isDeleted: true,
    });

    const totalNewNotificationsAfterDelete =
      totalNewNotifications > 0
        ? totalNewNotifications - 1
        : totalNewNotifications;

    if (!error) {
      this.setState({
        notifications: {
          ...notifications,
          data: newNotifications,
        },
        totalNewNotifications: totalNewNotificationsAfterDelete,
      });
    }
  }

  async handleClearAllNotifications() {
    const { notifications } = this.state;

    const { error } = await updateUserNotifications({
      notificationIds: notifications.data.map(notification => notification.id),
      isRead: true,
      isDeleted: true,
    });

    if (!error) {
      // return to previous state
      this.setState(prevState => ({
        notifications: {
          ...prevState.notifications,
          data: [],
          page: 0,
        },
        totalNewNotifications: 0,
      }));
    }
  }

  handleNotificationCardClick(selectedNotificationId) {
    const { notifications } = this.state;

    const selectedNotification = notifications.data.find(
      notification => notification.id === selectedNotificationId
    );

    if (!selectedNotification) {
      return;
    }

    const { additionalData, notificationType } = selectedNotification;
    const { bookingCode } = additionalData;

    if (
      [
        TYPE_NOTIFICATION_TYPE_ASSIGN_DRIVER,
        TYPE_NOTIFICATION_TYPE_CONFIRM_BOOKING,
        TYPE_NOTIFICATION_TYPE_POTENTIAL_LATE,
        TYPE_NOTIFICATION_TYPE_REQUEST_REFUND,
        TYPE_NOTIFICATION_TYPE_REQUEST_RESCHEDULE,
      ].includes(notificationType)
    ) {
      NextRouter.push(
        createUrlWithQuery(
          path.airportTransport.bookingPickupManagement,
          constructPickupManagementQuery({
            tabType: PICKUP_MANAGEMENT_TAB_TYPE_ALL,
            bookingCode,
          })
        )
      );
    }
  }

  handleLoadPreviousNotifications() {
    this.requestNotifications();
  }

  render() {
    const { locale } = this.props;
    const {
      notifications: _notifications,
      isOpen,
      totalNewNotifications,
    } = this.state;
    const { data: notifications } = _notifications;

    return (
      <div className={notificationContainerPosition}>
        <NotificationCardContainer
          locale={locale}
          isVisible={isOpen}
          numberOfDisplayItems={DEFAULT_NOTIFICATION_COUNT}
          notifications={notifications}
          onNotificationCardCloseClick={this.handleNotificationCardCloseClick}
          onNotificationCardActionClick={this.handleNotificationCardClick}
          onClearAllNotificationClick={this.handleClearAllNotifications}
          onLoadPreviousClick={this.handleLoadPreviousNotifications}
        />

        <NotificationButton
          status={
            totalNewNotifications > 0
              ? TYPE_NOTIFICATION_BUTTON_STATE_ACTIVE
              : TYPE_NOTIFICATION_BUTTON_STATE_IDLE
          }
          totalUnread={totalNewNotifications}
          onClick={this.handleToggleNotificationIsOpen}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { isExternal, locale } = getPagePropertyState(state);

  return {
    isExternal,
    locale,
  };
};

export default connect(mapStateToProps)(NotificationContainer);
