import React from 'react';
import convert from 'htmr';
import { css, cx } from 'react-emotion';

import {
  Block,
  Box,
  BoxBody,
  BoxHeader,
  BoxFooter,
  Button,
  FlexBox,
  Icon,
  Text,
} from '@traveloka/soya-components';

import { getNotificationAction } from 'shared/utils/NotificationHandler';
import { getDifferenceTimeFromNowLabel } from 'shared/utils/Helper';

import {
  TYPE_NOTIFICATION_STATE_URGENT,
  TYPE_NOTIFICATION_STATE_RESOLVED,
  TYPE_NOTIFICATION_STATE_NEW,
} from 'shared/constants/type/index';

// Styles
const defaultCardStyle = css`
  border: none;
  border-radius: 6px;
  margin-bottom: 2px;

  &:hover {
    opacity: 0.8;
  }
`;
const defaultHeaderStyle = css`
  &#notifCardHeader {
    padding: 8px 4px 8px 12px;
    font-size: 12px;
    font-weight: normal;
  }
`;
const defaultBodyStyle = css`
  &#notifCardBody {
    padding: 0px 12px 8px;
  }
`;
const defaultFooterStyle = css`
  &#notifCardFooter {
    padding: 0px 12px 8px;
  }
`;
const notificationActionTextStyle = css`
  color: #fca000;
`;
const notificationResolvedTextStyle = css`
  color: #0bc175;
`;
const newNotificationCardStyle = css`
  background: #fffad9;
`;
const urgentNotificationCardStyle = css`
  background: #fce3e4;
`;
const resolveNotificationCardStyle = css`
  background: #fff;
`;
const headerDescriptionTextStyle = css`
  color: #087e8b;
`;
const closeIconStyle = css`
  cursor: pointer;
`;
// Mixins
const notificationActionButtonStyle = css`
  &#notifActionBtn {
    ${notificationActionTextStyle}
    padding: 0;
    text-align: left;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default class NotificationCard extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickNotificationAction = this.handleClickNotificationAction.bind(
      this
    );
    this.handleCloseNotification = this.handleCloseNotification.bind(this);
  }

  handleCloseNotification() {
    const { notificationId, onCloseClick } = this.props;

    if (typeof onCloseClick === 'function') {
      onCloseClick(notificationId);
    }
  }

  handleClickNotificationAction() {
    const { notificationId, onNotificationActionClick } = this.props;

    if (typeof onNotificationActionClick === 'function') {
      onNotificationActionClick(notificationId);
    }
  }

  render() {
    const {
      headerTime,
      headerTitle,
      content,
      locale,
      notificationState,
      notificationType,
    } = this.props;

    const isResolved = notificationState === TYPE_NOTIFICATION_STATE_RESOLVED;

    const notificationTypeLabel = getNotificationAction(
      notificationType,
      isResolved,
      locale
    );

    return (
      <Box
        className={cx(defaultCardStyle, {
          [newNotificationCardStyle]:
            notificationState === TYPE_NOTIFICATION_STATE_NEW,
          [urgentNotificationCardStyle]:
            notificationState === TYPE_NOTIFICATION_STATE_URGENT,
          [resolveNotificationCardStyle]:
            notificationState === TYPE_NOTIFICATION_STATE_RESOLVED,
        })}
      >
        <BoxHeader id={'notifCardHeader'} className={defaultHeaderStyle}>
          <FlexBox halign={'between'} valign={'top'}>
            <Block>
              <Text className={headerDescriptionTextStyle}>{headerTitle}</Text>
              <Text className={headerDescriptionTextStyle}>{' • '}</Text>
              <Text size={12}>
                {getDifferenceTimeFromNowLabel(headerTime * 1000, locale)}
              </Text>
            </Block>

            <Icon
              className={closeIconStyle}
              icon={'close'}
              size={12}
              onClick={this.handleCloseNotification}
            />
          </FlexBox>
        </BoxHeader>

        <BoxBody id={'notifCardBody'} className={defaultBodyStyle}>
          <Text>{content ? convert(content) : ''}</Text>
        </BoxBody>

        <BoxFooter id={'notifCardFooter'} className={defaultFooterStyle}>
          {!isResolved ? (
            <Button
              id={'notifActionBtn'}
              className={notificationActionButtonStyle}
              type={'button'}
              variant={'link'}
              onClick={this.handleClickNotificationAction}
            >
              <Text>{notificationTypeLabel || ''}</Text>
            </Button>
          ) : (
            <Text className={notificationResolvedTextStyle}>
              {notificationTypeLabel || ''}
            </Text>
          )}
        </BoxFooter>
      </Box>
    );
  }
}
