import React from 'react';
import withRouter from './withRouter';

import Layout from 'shared/components/Layout/Layout';

const withLayout = (WrappedComponent, additionalProps = {}) => {
  const { permissions, isWide } = additionalProps;

  class HocComponent extends React.Component {
    static async getInitialProps(ctx) {
      return (
        WrappedComponent.getInitialProps &&
        (await WrappedComponent.getInitialProps(ctx))
      );
    }

    render() {
      const { router } = this.props;

      return (
        <Layout permissions={permissions} url={router.asPath} isWide={isWide}>
          <WrappedComponent {...this.props} />
        </Layout>
      );
    }
  }

  return withRouter(HocComponent);
};

export default withLayout;
