import React from 'react';
import { css, cx } from 'react-emotion';

import { Button, FlexBox } from '@traveloka/soya-components';

import NotificationCard from './NotificationCard';

import { CLEAR_ALL_NOTIFICATION } from 'translations/notification';

const cardContainerPositionStyle = css`
  position: fixed;
  right: 100px;
  bottom: 16px;
  max-height: 300px;
  width: 300px;
  overflow: hidden;
  overflow-y: scroll;
  background: #ffffff;
  border: 1px solid #f7f9fa;
  box-shadow: 0px 4px 10px rgba(3, 18, 26, 0.15);
  border-radius: 6px;
  transition: visibility 500ms ease-in-out, opacity 500ms ease-in-out;
`;
const clearAllNotificationStyle = css`
  padding: 6px 6px 12px;
  text-align: center;
  font-size: 14px;
`;
const visibilityStyle = isVisible => css`
  visibility: ${isVisible ? 'visible' : 'hidden'};
  opacity: ${isVisible ? 1 : 0};
`;

export default class NotificationCardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickClearAllNotification = this.handleClickClearAllNotification.bind(
      this
    );
    this.handleClickNotificationActionText = this.handleClickNotificationActionText.bind(
      this
    );
    this.handleClickNotificationCloseIcon = this.handleClickNotificationCloseIcon.bind(
      this
    );
    this.handleLoadPreviousNotification = this.handleLoadPreviousNotification.bind(
      this
    );
  }

  handleClickNotificationCloseIcon(notificationId) {
    const { onNotificationCardCloseClick } = this.props;

    if (typeof onNotificationCardCloseClick === 'function') {
      onNotificationCardCloseClick(notificationId);
    }
  }

  handleClickNotificationActionText(notificationId) {
    const { onNotificationCardActionClick } = this.props;

    if (typeof onNotificationCardActionClick === 'function') {
      onNotificationCardActionClick(notificationId);
    }
  }

  handleClickClearAllNotification() {
    const { onClearAllNotificationClick } = this.props;

    if (typeof onClearAllNotificationClick === 'function') {
      onClearAllNotificationClick();
    }
  }

  handleLoadPreviousNotification() {
    const { onLoadPreviousClick } = this.props;

    if (typeof onLoadPreviousClick === 'function') {
      onLoadPreviousClick();
    }
  }

  renderNotificationCards() {
    const { locale, notifications } = this.props;

    return notifications.map(notification => (
      <NotificationCard
        key={notification.id}
        locale={locale}
        headerTitle={notification.title}
        headerTime={notification.createdTimestamp}
        content={notification.content}
        notificationId={notification.id}
        notificationState={notification.state}
        notificationType={notification.notificationType}
        onCloseClick={this.handleClickNotificationCloseIcon}
        onNotificationActionClick={this.handleClickNotificationActionText}
      />
    ));
  }

  handleScrollToBottom() {
    const bottomSection = window.document.getElementById('bottom');
    bottomSection.scrollIntoView();
  }

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (isVisible) {
      this.handleScrollToBottom();
    }
  }

  render() {
    const { children, locale, isVisible = false } = this.props;

    return (
      <div
        className={cx(cardContainerPositionStyle, visibilityStyle(isVisible))}
      >
        <FlexBox
          halign={'center'}
          valign={'middle'}
          className={clearAllNotificationStyle}
        >
          <Button
            padding={0}
            variant={'link-black'}
            onClick={this.handleScrollToBottom}
          >
            {'Go to the latest notification'}
          </Button>
        </FlexBox>
        {/* <FlexBox
          halign={'center'}
          valign={'middle'}
          className={clearAllNotificationStyle}
        >
          {notifications.length > numberOfDisplayItems && (
            <Button
              padding={0}
              variant={'link-black'}
              onClick={this.handleLoadPreviousNotification}
            >
              {LOAD_PREVIOUS[locale]}
            </Button>
          )}
        </FlexBox> */}
        {this.renderNotificationCards()}

        {children}

        <FlexBox
          halign={'center'}
          valign={'middle'}
          className={clearAllNotificationStyle}
        >
          <Button
            padding={0}
            variant={'link-black'}
            onClick={this.handleClickClearAllNotification}
          >
            {CLEAR_ALL_NOTIFICATION[locale]}
          </Button>
        </FlexBox>
        <div id={'bottom'} />
      </div>
    );
  }
}
