import { EN, ID } from 'shared/constants/locale';

export const CARPOOL_SUMMARY = {
  [EN]: 'Car Pool Summary',
  [ID]: 'Car Pool Summary', // TODO add translations
};

export const DRIVER_ABSENT = {
  [EN]: 'Driver Absent',
  [ID]: 'Driver Absent', // TODO add translations
};

export const DRIVER_NAME = {
  [EN]: `Driver's Name`,
  [ID]: 'Nama Driver',
};

export const ABSENT = {
  [EN]: 'Absent',
  [ID]: 'Absen', // TODO add translations
};

export const BOOKING_ACTION = {
  [EN]: 'Booking Action',
  [ID]: 'Booking Action', // TODO add translations
};

export const DRIVER_OUT = {
  [EN]: 'Driver Out',
  [ID]: 'Driver Out', // TODO add translations
};

export const DRIVER_NOT_FOUND = {
  [EN]: 'There is no driver in the Car Pool',
  [ID]: 'Tidak ada driver di Pool Mobil', // TODO add translations
};

export const STANDBY = {
  [EN]: 'Standby',
  [ID]: 'Standby', // TODO add translations
};

export const WAITING_FOR_BOOKING = {
  [EN]: 'Waiting for booking',
  [ID]: 'Waiting for booking', // TODO add translations
};

export const ASSIGNED = {
  [EN]: 'Assigned',
  [ID]: 'Assigned', // TODO add translations
};

export const REST = {
  [EN]: 'Rest',
  [ID]: 'Rest', // TODO add translations
};

export const LEAVE_POOL = {
  [EN]: 'Leave pool',
  [ID]: 'Leave pool', // TODO add translations
};

export const ABSENT_OUT = {
  [EN]: 'Absent out',
  [ID]: 'Absent out', // TODO add translations
};

export const ON_THE_WAY = {
  [EN]: 'On the way',
  [ID]: 'On the way', // TODO add translations
};

export const CUSTOMER_HELP = {
  [EN]: 'Customer Help',
  [ID]: 'Customer Help', // TODO add translations
};

export const CUSTOMER_NAME = {
  [EN]: 'Customer Name',
  [ID]: 'Nama Pelanggan',
};

export const CUSTOMER_FIRST_NAME = {
  [EN]: 'Customer First Name',
  [ID]: 'Nama Depan Pelanggan',
};

export const CUSTOMER_PHONE_NUMBER = {
  [EN]: 'Customer Phone Number',
  [ID]: 'Nomor Telepon Pelanggan',
};

export const PICKUP_INSTRUCTION = {
  [EN]: 'Pick up Instruction',
  [ID]: 'Instruksi Penjemputan',
};

export const BOOKING_TYPE = {
  [EN]: 'Booking Type',
  [ID]: 'Jenis Pesanan',
};

export const EXPECTED_PICKUP_TIME = {
  [EN]: 'Expected Pick up Time',
  [ID]: 'Perkiraan Waktu Jemput',
};

export const SUPPLIER = {
  [EN]: 'Supplier',
  [ID]: 'Supplier',
};

export const SUPPLIER_PHONE_NUMBER = {
  [EN]: 'Supplier Phone Number',
  [ID]: 'Nomor Telepon Supplier',
};

export const CHANGE_DRIVER = {
  [EN]: 'Change Driver',
  [ID]: 'Ganti Supir',
};

export const CURRENT_DRIVER = {
  [EN]: 'Current Driver',
  [ID]: 'Supir Saat Ini',
};

export const ABSENT_TIME = {
  [EN]: 'Absent Time',
  [ID]: 'Waktu Absen',
};
